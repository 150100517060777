<template>
  <section class="featured">
    <div class="featured--container">
      <h2 class="text__center featured--heading container">Featured Products</h2>
      <VueSlickCarousel v-bind="settings">
        <router-link
          :to="`/products/${product._id}`"
          v-for="product in getFeaturedProducts"
          :key="product._id"
          class="featured-products--item tile link__none pos__rel"
        >
          <div class="flex__center">
            <img :src="product.images[0]" />
          </div>
          <p class="text__bold pre-wrap text__center">{{ product.name }}</p>
          <!-- <Respa :status="product.respaStatus" /> -->
        </router-link>
      </VueSlickCarousel>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";
//import Respa from "../Products/Respa";
export default {
  components: {
    VueSlickCarousel
    
  },
  data() {
    return {
      settings: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        touchThreshold: 5,
        initialSlide: 0,
        focusOnSelect: false,
        responsive: [
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["getFeaturedProducts"])
  }
};
</script>

<style scoped lang="scss">
.featured--container {
  min-height: 350px;
  padding: 25px 25px 25px;
  background-color: lighten($frost, 20%);
  border-radius: 5px;
}
.featured--heading {
  font-size: 2rem;
  margin: 0;
}
.featured-products--item {
  @include grid($cols: 200px, $row-gap: 10px);
  transition: all 0.25s ease-in-out;
  img {
    width: 100%;
    height: 100%;
  }
  p {
    height: 56px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  &:hover {
    @include shadow;
    background-color: lighten($frost, 15%);
  }
}
@media (min-width: $sm) {
  .featured--heading {
    font-size: 2.2rem;
  }
}
</style>