<template>
  <router-link :to="link" class="new-product--flag flex__center link__none">
    <font-awesome-icon icon="certificate" size="3x" />
    <span>NEW</span>
  </router-link>
</template>

<script>
export default {
  props: ["link"]
};
</script>

<style scoped lang="scss">
.new-product--flag {
  @include pos-abs($pos: absolute, $top: -15px, $left: null, $right: -15px);
  color: $peacock;
  font-size: 0.85rem;
  width: 40px;
  height: 40px;
}
span {
  position: absolute;
  color: $powder;
  transform: rotate(30deg);
}
</style> 