<template>
  <div class="slick-carousel--container">
    <VueSlickCarousel v-bind="settings">
      <div v-for="product in getCustomProducts" :key="product._id">
        <router-link :to="`/products/${product._id}`" class="link__none tile pos__rel">
          <img :src="product.images[0]" />
          <p class="text__bold text__center">{{ product.name }}</p>
        </router-link>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import { mapGetters } from "vuex";
export default {
  props: ["homeTile"],
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      settings: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        touchThreshold: 5,
        initialSlide: 0,
        focusOnSelect: false,
        responsive: [
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["getProducts"]),
    getCustomProducts() {
      if (!this.homeTile) return [];
      if (!this.getProducts) return [];
      return this.homeTile.products
        .map(id => {
          return this.getProducts.filter(product => product._id === id)[0];
        })
        .filter(item => {
          return item.status !== "test";
        });
    }
  }
};
</script>

<style scoped lang="scss">
.tile {
  @include grid($cols: 1fr, $row-gap: 3px);
  transition: all 0.25s ease-in-out;
  img {
    height: 100%;
    width: 100%;
    margin: auto;
  }
  p {
    height: 56px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  &:hover {
    @include shadow;
    background-color: lighten($frost, 15%);
  }
}
</style>