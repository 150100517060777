<template>
  <section class="margin__md home-other">
    <!-- <h2 class="mb-15">Other</h2> -->
    <div class="home-other__grid">
      <router-link to="/my-account" class="hero--tile">
        <div
          class="hero--tile__img"
          style="background-image: url(https://cdn.fbhl.com/WOW-Assets/hero-images/mobile-phone-8-4-2021.png);"
        ></div>
        <p class="tile-link">
          My Account
          <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
        </p>
      </router-link>
      <router-link to="/new-order/order-details" class="hero--tile">
        <div
          class="hero--tile__img"
          style="background-image: url(https://cdn.fbhl.com/WOW-Assets/hero-images/shopping-cart-8-4-2021.png);"
        ></div>
        <p class="tile-link">
          New Order
          <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
        </p>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.home-other__grid {
  @include grid($cols: 1fr, $row-gap: 15px, $col-gap: 15px);
}
.hero--tile {
  height: 250px;
}
.home-other {
  margin-top: 75px;
}
@media (min-width: $md) {
  .home-other__grid {
    grid-template-columns: 1fr 1fr;
  }
  .hero--tile {
    height: 350px;
  }
}
@media (min-width: $lg) {
  .hero--tile {
    height: 425px;
  }
}
</style>