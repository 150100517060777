<template>
  <main class="page--home">
    <Header />
    <div class="container page--home-content">
      <Featured />
      <!-- <section class="tile margin__lg">
        <h1 class="text__center padding__sm">Make Your Clients Say WOW!</h1>
        <p class="text__center text__lg home--subheading">
          We offer several personalized gifts to offer your borrowers as well as gifts for your co-workers and realtor partners.
          Start a new order or browse our products to see what we can offer you!
        </p>
      </section>-->
      <section class="margin__md">
        <!-- <h2 class="mb-15">Place an Order</h2> -->
        <HeroSmall :links="heroSmall.links" :calls="heroSmall.calls" :imgs="heroSmall.imgs" />
      </section>
      <section v-if="getHomeTiles[0].status" class="margin__sm">
        <h2>{{ getHomeTiles[0].name }}</h2>
        <CustomTile :homeTile="getHomeTiles[0]" />
        <!-- <p class="text__right">
          <router-link to="/products" class="breadcrumb">
            See More
            <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
          </router-link>
        </p> -->
      </section>
      <section class="margin__sm">
        <h2>New Products</h2>
        <RecentlyAdded />
        <!-- <p class="text__right">
          <router-link to="/products" class="breadcrumb">
            See More
            <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
          </router-link>
        </p>-->
      </section>
      <section v-if="getHomeTiles[1].status" class="margin__sm">
        <h2>{{ getHomeTiles[1].name }}</h2>
        <CustomTile :homeTile="getHomeTiles[1]" />
        <!-- <p class="text__right">
          <router-link to="/products" class="breadcrumb">
            See More
            <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
          </router-link>
        </p>-->
      </section>
      <section class="margin__sm">
        <h2>Shop By Category</h2>
        <Categories :categories="getCategories" />
        <!-- <p class="text__right">
          <router-link to="/products" class="breadcrumb">
            See More
            <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
          </router-link>
        </p>-->
      </section>
    </div>
    <About />
    <div class="container">
      <div class="tile-heading mt-15">
        <h2 class="text__center">
          Hello,
          <span v-if="getUser.isLoggedIn">{{ getUser.username }}</span>
          <router-link v-else to="/login" class="link__none">Sign In</router-link>
        </h2>
        <hr />
      </div>
      <section class="grid__two margin__md">
        <section>
          <div class="flex__between mb-15">
            <h2>Favorites</h2>
            <p v-if="getUser.isLoggedIn" class="text__right">
              <router-link to="/my-account/favorites" class="breadcrumb">
                See More
                <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
              </router-link>
            </p>
          </div>
          <div v-if="!getUser.isLoggedIn" class="flex__col account__default">
            <p class="text__center text__bold">Sign in to view your favorites</p>
            <router-link to="/login" class="breadcrumb text__bold text__center">Sign In</router-link>
          </div>
          <favorite-grid v-else :favorites="getHomeFavorites" />
        </section>
        <section>
          <div class="flex__between mb-15">
            <h2>Orders</h2>
            <p v-if="getUser.isLoggedIn" class="text__right">
              <router-link to="/my-account/past-orders" class="breadcrumb">
                See More
                <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
              </router-link>
            </p>
          </div>
          <div v-if="!getUser.isLoggedIn" class="flex__col account__default">
            <p class="text__center text__bold">Sign in to view your favorites</p>
            <router-link to="/login" class="breadcrumb text__bold text__center">Sign In</router-link>
          </div>
          <order-grid v-else :orders="getHomeOrders" />
        </section>
      </section>
      <Final />
      <section v-if="getUser.isLoggedIn" class="tile margin__md">
        <h2>Product Suggestion</h2>
        <Suggest />
      </section>
      <!-- <section class="margin__md">
        <h2 class="mb-15">Common Links</h2>
        <Hero :links="hero.links" :calls="hero.calls" :imgs="hero.imgs" />
      </section>-->
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
// import Banner from "./Banner";
import Header from "./Header";
import Featured from "./Featured";
import About from "./About";
import Categories from "./Categories";
import CustomTile from "./CustomTile";
import HeroSmall from "../../HeroSmall";
// import Hero from "../../Hero";
import RecentlyAdded from "./RecentlyAdded";
import Final from "./Final";
import Suggest from "./Suggest";
export default {
  components: {
    // Banner,
    Header,
    Featured,
    About,
    Categories,
    CustomTile,
    HeroSmall,
    // Hero,
    RecentlyAdded,
    Final,
    Suggest
  },
  data() {
    return {
      heroSmall: {
        imgs: [
          "https://cdn.fbhl.com/WOW-Assets/hero-images/shopping-cart-8-4-2021.png",
          "https://cdn.fbhl.com/WOW-Assets/hero-images/shopping-bag-8-4-2021.png",
          "https://cdn.fbhl.com/WOW-Assets/urban-405.png"
        ],
        links: [
          "/new-order/order-details",
          "/products",
          "/batch-order/order-details"
        ],
        calls: ["New Order", "Products", "Batch Order"]
      },
      hero: {
        imgs: [
          "https://cdn.fbhl.com/WOW-Assets/pale-order-completed.png",
          "https://cdn.fbhl.com/WOW-Assets/pablo-867.png",
          "https://cdn.fbhl.com/WOW-Assets/marginalia-699.png",
          "https://cdn.fbhl.com/WOW-Assets/cherry-657.png",
          "https://cdn.fbhl.com/WOW-Assets/clip-1395.png"
        ],
        links: [
          "/new-order/order-details",
          "/my-account/past-orders",
          "/products",
          "/my-account",
          "/help"
        ],
        calls: ["New Order", "My Orders", "Products", "My Account", "Help"]
      },
      userFavorites: null,
      userOrders: null
    };
  },
  metaInfo: {
    title: "Home | WOW"
  },
  computed: {
    ...mapGetters([
      "getCategories",
      "getUser",
      "getHomeTiles",
      "getHomeOrders",
      "getHomeFavorites"
    ])
  }
};
</script>

<style scoped lang="scss">
.page--home-content {
  margin-top: -75px;
  position: relative;
  padding: 10px;
}
.home--subheading {
  max-width: 750px;
  margin: auto;
}
.account__default {
  min-height: 400px;
  height: 95%;
  background-color: $frost;
}
</style>