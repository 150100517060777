<template>
  <section class="contact mt-15">
    <p>
      <i>You must be logged into a WOW account in order to complete this form.</i>
      Please fill out the below form to suggest a new product.
      <br />Include any links to product pages if you have them.
    </p>
    <section v-if="!submitted" class="contact-form">
      <label :class="[errors.subject ? 'form--error' : null ]">
        Subject*
        <input type="text" v-model="form.subject" placeholder="subject here" />
      </label>
      <form-error :message="errors.topic" />
      <label :class="[errors.message ? 'form--error' : null ]">
        Your Message*
        <Editor :api-key="apiKey" :init="config" v-model="form.message" />
      </label>
      <form-error :message="errors.message" />
      <p
        class="text__sm text__center"
      >Your message will be submitted to a ticketing queue for a WOW representative to view. Please be patient in waiting for a response.</p>
      <button class="btn btn__green text__md" @click="submit">Send Message</button>
    </section>
    <section v-else class="contact-complete">
      <p
        class="text__bold text__center"
      >Your message has been submitted, a WOW representative will look over your suggestion and see if it works for the WOW store</p>
    </section>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { createError } from "../../../libs/flash-messages";
import { basicConfig } from "../../../plugins/tinymce";
export default {
  data() {
    return {
      isValidated: true,
      submitted: false,
      form: {
        email: "",
        name: "",
        topic: "Product Idea",
        subject: "New Product Idea",
        message: ""
      },
      errors: {
        subject: null,
        message: null
      }
    };
  },
  computed: {
    ...mapGetters(["getUserDetails"]),
    apiKey() {
      return basicConfig.apiKey;
    },
    config() {
      return basicConfig.config;
    }
  },
  methods: {
    ...mapActions(["startSetIsSubmitting", "createContactTicket"]),
    async submit() {
      if (
        !this.getUserDetails ||
        !this.getUserDetails.email ||
        !this.getUserDetails.displayName
      ) {
        createError(
          "Access Denied",
          "Only signed in users may submit this form"
        );
        return;
      }
      this.validate();
      if (!this.isValidated) return;
      this.startSetIsSubmitting({ bool: true, heading: "Sending Message" });
      try {
        await this.createContactTicket(this.form);
        this.startSetIsSubmitting({ bool: false });
        this.submitted = true;
      } catch (err) {
        this.startSetIsSubmitting({ bool: false });
      }
    },
    validate() {
      this.isValidated = true;
      this.errors.subject =
        this.form.subject === "" ? "Please enter a subject" : null;
      this.errors.message =
        this.form.message === "" ? "Please enter a message" : null;
      for (let key in this.errors) {
        if (this.errors[key] !== null) {
          this.isValidated = this.isValidated && false;
        } else {
          this.isValidated = this.isValidated && true;
        }
      }
    }
  },
  mounted() {
    if (this.getUserDetails) {
      this.form.email = this.getUserDetails.email;
      this.form.name = this.getUserDetails.displayName;
    }
  }
};
</script>

<style scoped lang="scss">
.contact {
  @include grid($cols: 1fr, $row-gap: 25px);
}
.contact .contact-form {
  @include grid($cols: 1fr, $row-gap: 25px, $align: center);
  max-width: 700px;
  margin: auto;
  label {
    @include grid($cols: 1fr, $row-gap: 3px);
  }
}
.contact .contact-complete {
  height: 200px;
  @include grid($cols: 1fr, $col-gap: 25px, $align: center);
  max-width: 500px;
  margin: auto;
}
</style>