<template>
  <div class="slick-carousel--container">
    <VueSlickCarousel v-bind="settings">
      <div v-for="item in categories" :key="item._id" class="carousel-list-item">
        <router-link :to="`/products?category=${item._id}`" class="carousel-list-item--interior">
          <img :src="`${ getCategoryImage(item._id) }`" />
          <p class="text__center text__bold">{{ item.name }}</p>
        </router-link>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueSlickCarousel from "vue-slick-carousel";
export default {
  props: {
    categories: {
      // array of objects with link, img, subheading
      type: Array
    }
  },
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      settings: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        touchThreshold: 5,
        initialSlide: 0,
        focusOnSelect: false,
        responsive: [
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["getProducts"])
  },
  methods: {
    getCategoryImage(id) {
      const products = this.getProducts.filter(item => {
        return item.categories.includes(id);
      });
      if (products.length <= 0)
        return "https://cdn.fbhl.com/Team-Banner-Images/opt1.jpg";
      const img =
        products[Math.floor(Math.random() * products.length)].images[0];
      return img;
    }
  }
};
</script>

<style scoped lang="scss">
/* .slick-carousel--container {
  padding: 0px 15px 25px 15px;
} */
.carousel-list-item {
  width: 200px;
  margin: 10px;
  margin-bottom: 15px;
  img {
    transition: all 0.25s ease-in-out;
  }
  &:hover img {
    @include shadow;
    background-color: lighten($frost, 15%);
  }
}
</style>