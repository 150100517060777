<template>
  <div class="slick-carousel--container">
    <VueSlickCarousel v-bind="settings">
      <div v-for="product in getRecentProducts" :key="product._id">
        <router-link :to="`/products/${product._id}`" class="link__none tile pos__rel">
          <img :src="product.images[0]" />
          <p class="text__bold text__center">{{ product.name }}</p>
          <!-- <div class="flex__right">
            <router-link :to="`/products/${product._id}`" class="breadcrumb">
              View Product
              <font-awesome-icon icon="arrow-right" size="sm" class="ml-10" />
            </router-link>
          </div>-->
          <NewBadge :link="`/products/${product._id}`" />
        </router-link>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import { mapGetters } from "vuex";
import NewBadge from "./NewBadge";
export default {
  components: {
    VueSlickCarousel,
    NewBadge
  },
  data() {
    return {
      settings: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        rows: 2,
        slidesPerRow: 1,
        touchThreshold: 5,
        initialSlide: 0,
        focusOnSelect: false,
        responsive: [
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["getRecentProducts"])
  }
};
</script>

<style scoped lang="scss">
.tile {
  @include grid($cols: 1fr, $row-gap: 3px);
  transition: all 0.25s ease-in-out;
  margin: 15px 0;
  img {
    height: 100%;
    width: 100%;
    margin: auto;
  }
  p {
    height: 56px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  &:hover {
    @include shadow;
    background-color: lighten($frost, 15%);
  }
}
</style>